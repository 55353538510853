@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    background-color: #d1d5db;
    color: #020204;
    height: 100%;
    overflow-x: hidden;
    text-size-adjust: 100%;
}

main {
    overflow: auto;
    scroll-behavior: smooth;
}

* {
    font-family: "Cairo", sans-serif;
    transition: all 200ms ease-in-out;
}
*:focus,
*:active {
    outline: none !important;
    border: 1;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

#root {
    height: 100%;
}

.font-bein-normal {
    font-family: "'beIN Normal'";
}

.font-bein-bold {
    font-family: "beINBlack";
}
.font-bahij-bold {
    font-family: "'Bahij TheSansArabic Bold'";
}
.font-bahij-normal {
    font-family: "'Bahij TheSansArabic SemiLight'";
}

.bm-menu {
    background: #fafaf9;
}

.bm-overlay {
    background: rgba(0, 0, 0, 0) !important;
}

.text-primary {
    color: #020204;
}

.bg-primary {
    color: #ecf0f3;
}

.bg-primary-dark {
    color: #04001c;
}

.neu-card {
    border-radius: 10px;
    background: linear-gradient(145deg, #d4d8db, #fdffff);
    box-shadow: 8px 8px 8px #c9cccf, 8px 8px 8px #ffffff;
}
.neu-card:hover {
    background: #ecf0f3;
    box-shadow: 10px 10px 20px #c9cccf, 10px 10px 20px #ffffff;
}

.overflow-y-hidden-important {
    overflow-y: hidden !important;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bounce-in {
    animation: bounce-in-frames 300ms;
}

.bounce-out {
    animation: bounce-out-frames 150ms;
    display: none;
}

@keyframes bounce-in-frames {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0) scaleY(0.9);
    }

    75% {
        transform: translate3d(0, 10px, 0) scaleY(0.95);
    }

    90% {
        transform: translate3d(0, -5px, 0) scaleY(0.985);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes bounce-out-frames {
    20% {
        transform: translate3d(0, 10px, 0) scaleY(0.985);
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, -20px, 0) scaleY(0.9);
    }

    to {
        opacity: 0;
        display: none;
    }
}

@page {
    size: 8cm auto;
    width: 8cm;
    height: 100%;
    margin: 0;
    margin-left: 5px !important;
}

.popup-in {
    animation: popup-in-frames 400ms ease-out;
    height: 280px;
}

.popup-out {
    animation: popup-out-frames 300ms ease-in;
    height: 50px;
}

@keyframes popup-in-frames {
    0% {
        height: 50px;
        border-radius: 90;
    }
    100% {
        height: 280px;
        border-radius: 45;
    }
}

@keyframes popup-out-frames {
    0% {
        height: 280px;
        border-radius: 45;
    }
    100% {
        height: 50px;
        border-radius: 90;
    }
}

@keyframes popup-frames {
    from {
        height: 1%;
        top: 95%;
        border: radius 90px;
        opacity: 0.3;
    }
    to {
        height: 90%;
        top: 5%;
        border: radius 45px;
        opacity: 1;
    }
}

.popup {
    animation: popup-frames 400ms ease-out;
    height: 90%;
    top: 5%;
}

@keyframes popdown-frames {
    from {
        height: 90%;
        top: 5%;
        border: radius 45px;
        opacity: 1;
    }
    to {
        height: 0%;
        top: 96%;
        border: radius 90px;
        opacity: 0.3;
    }
}

.popdown {
    animation: popdown-frames 400ms ease-in;
    height: 0%;
    top: 96%;
}

.bgTransfrom {
    animation: bgTransform-frames 400ms ease-in;
    background-color: rgba(0, 0, 0, 0.8);
}

@keyframes bgTransform-frames {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }
    100% {
        background-color: rgba(0, 0, 0, 0.8);
    }
}
